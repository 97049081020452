import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import DataSimpanan from '@/pages/Simpanan/DataSimpanan';
import MutasiSimpanan from '@/pages/Simpanan/MutasiSimpanan';
import PengajuanPinjaman from '@/pages/Pinjaman/PengajuanPinjaman';
import DaftarPinjaman from '@/pages/Pinjaman/DaftarPinjaman';
import Login from "@/pages/Login/Login";

Vue.use(Router);

  const routes = [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { auth: true }
      },
      {
        path: 'datasimpanan',
        name: 'DataSimpanan',
        component: DataSimpanan,
        meta: { auth: true }
      },
      {
        path: 'mutasisimpanan',
        name: 'MutasiSimpanan',
        component: MutasiSimpanan,
        meta: { auth: true }
      },
      {
        path: 'pengajuanpinjaman',
        name: 'PengajuanPinjaman',
        component: PengajuanPinjaman,
        meta: { auth: true }
      },
      {
        path: 'daftarpinjaman',
        name: 'DaftarPinjaman',
        component: DaftarPinjaman,
        meta: { auth: true }
      },
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ]

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  }) 

  router.beforeEach((to, from, next) => {
    // jika routing ada meta auth-nya maka
    if (to.matched.some(record => record.meta.auth)) {
      // jika user adalah guest
      if(store.getters['auth/guest']){
            next('/login')
      }
      else{
        next()
      } 
    }
    else{
        next()
    }
  })
  
  // router.beforeEach((to, from, next) => { 
  //   if (to.matched.some(record => record.meta.auth)) {
  //     if (store.getters.isLoggedIn && store.getters.user) {
  //       next()
  //       return
  //     }
  //     next('/login')
  //   }
  
  //   if (to.matched.some(record => record.meta.guest)) {
  //     if (!store.getters.isLoggedIn) {
  //       next()
  //       return
  //     }
  //     next('/dashboard')
  //   }
  
  //   next()
  // })
  
  export default router

