<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">Daftar Pinjaman</h1>
        <!-- <v-col cols=12>
            <v-radio-group
            v-model="row"
            row
            >
            <v-radio
                label="Belum Lunas"
                value="5"
            ></v-radio>
            <v-radio
                label="Lunas"
                value="6"
            ></v-radio>
            </v-radio-group>
        </v-col> -->
    </v-row>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Aksi</th>
                        <th class="text-center">No</th>                        
                        <th class="text-center">No Pinjaman</th>
                        <th class="text-center">Tgl Pinjam</th>
                        <th class="text-center">Total Pinjam</th>
                        <th class="text-center">Bunga</th>
                        <th class="text-center">Sistem Bunga</th>
                        <th class="text-center">Sisa Pinjaman</th>
                        <th class="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">
                        <td>
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                class="text-capitalize button-shadow mr-1"
                                @click="detail(data.notrs)"
                                >Detail</v-btn>
                        </td>
                        <td>{{ index+1 }}</td>
                        <td>{{ data.notrs }}</td>
                        <td>{{ data.tgl }}</td>
                        <td>{{ data.totalpeminjaman.toLocaleString() }}</td>
                        <td>{{ data.bungapinjam }} %</td>
                        <td>{{ (data.sistempinjam=="0" ? "Tetap" : "Menurun") }}</td>
                        <td>{{ data.sisapinjaman.toLocaleString() }}</td>
                        <td>{{ status(data.statusproses) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>
    <div class="tables-basic history" v-show="isShowing">
        <center><h4 class="">Histori Pembayaran Pinjaman</h4></center>
        <div v-for="d in dataPinjaman" :key="d.notrs">
            <v-row>
                <v-col cols="col-12" lg="4">
                    <v-text-field label="Tanggal Pinjam"
                                :value="d.tgl"
                                readonly></v-text-field>
                    <v-text-field label="Total Pinjaman"
                                :value="d.totalpeminjaman.toLocaleString()"
                                readonly></v-text-field>
                    <v-text-field label="Sistem Bunga"
                                :value='(d.sistempinjam=="0" ? "Tetap" : "Menurun")'
                                readonly></v-text-field>
                </v-col>
                <v-col cols="col-12" lg="4"></v-col>
                <v-col cols="col-12" lg="4">
                    <v-text-field label="Bunga"
                                :value="d.bungapinjam+' %'"
                                readonly></v-text-field>
                    <v-text-field label="Jangka Waktu (Bln)"
                                :value="d.lamapinjam"
                                readonly></v-text-field>
                    <v-text-field label="Sisa Pinjaman"
                                :value="d.sisapinjaman.toLocaleString()"
                                readonly></v-text-field>
                </v-col>
            </v-row>
        </div>
        <v-card class="mb-1">
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-center">No</th>
                    <th class="text-center">Tanggal</th>                        
                    <th class="text-center">Pokok</th>
                    <th class="text-center">Bunga</th>
                    <th class="text-center">Denda</th>
                    <th class="text-center">Total Bayar</th>
                    <th class="text-center">Saldo Akhir</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(data, index) in dataPembayaran" :key="data.pk">
                    <td>{{ index+1 }}</td>
                    <td>{{ data.tgl }}</td>
                    <td>{{ data.pokok.toLocaleString() }}</td>
                    <td>{{ data.bunga.toLocaleString() }}</td>
                    <td>{{ data.denda.toLocaleString() }}</td>
                    <td>{{ data.totalbayar.toLocaleString() }}</td>
                    <td>{{ data.saldoakhir.toLocaleString() }}</td>
                </tr>
            </tbody>
        </v-simple-table>
        </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DaftarPinjaman',
  data () {
    return {
        row: '',
        isShowing:false,
        dataTable:[],
        dataPinjaman: [],
        dataPembayaran: [],
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    detail(notrs){
        let formData = {
            'notrs' : notrs,
        }

        this.axios.post('/listTPeminjamanByNotrs', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPinjaman = data;
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
        this.axios.post('/listHistoryPembayaran', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPembayaran = data;
                this.isShowing = true
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
    },
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
    }

    this.axios.post('/listTPeminjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>