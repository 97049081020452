<template>
  <v-container fluid>
    <div class="dashboard-page">
      <h1 class="page-title mt-10 mb-6">Dashboard</h1>
      <v-row>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-line</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ totalsimpanan }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>TOTAL SIMPANAN</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-pie</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{ sisapinjaman }}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>SISA PINJAMAN</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-cash</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{totaltagihan}}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>TAGIHAN BULAN INI</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              
              <div class="text-xl-h4">News</div>
              <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="news"
              :search="search"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import moment from 'moment';

export default {
  name: 'Dashboard',
  data () {
    return {
      totalsimpanan : 0,
      sisapinjaman : 0,
      totaltagihan:0,
       search: '',
        headers: [
          // {
          //   text: 'Dessert (100g serving)',
          //   align: 'start',
          //   filterable: false,
          //   value: 'name',
          // },
          { text: 'Judul', value: 'judul' },
          { text: 'Detail', value: 'detail' },
          { text: 'Gambar', value: 'gambar' },
          { text: 'Tanggal', value: 'tanggal' },
        ],
        news: [],
    }
  }, 
  computed: {
    ...mapGetters({
      guest : 'auth/guest',
      user : 'auth/user',
    }),
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
    }

    this.axios.post('/listNews')
    .then((response) => {
      let { data } = response.data
      this.news = data;
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })
    
    this.axios.post('/totalSimpanan', formData)
    .then((response) => {
      let { data } = response.data
      this.totalsimpanan = data.toLocaleString();
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })
    
    this.axios.post('/sisaPinjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.sisapinjaman = data.toLocaleString();
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })

    this.axios.post('/totalTagihan', formData)
    .then((response) => {
      let { data } = response.data
      this.totaltagihan = data.toLocaleString();
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })

  },
  methods: {
    ...mapActions({
        setAuth   : 'auth/set',
        setAlert : 'alert/set',
    }),
  },
}

</script>

<style src="./Dashboard.scss" lang="scss"/>
