// // import axios from '../plugins/axios'

// import axios from "axios"

// let config = {
//     baseURL: process.env.VUE_APP_API_URL + '/v1',
//   };
  
//   const _axios = axios.create(config);

// export function setHeaderToken(token) {
//     _axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
// }

// export function removeHeaderToken() {
//     delete _axios.defaults.headers.common['Authorization']
// }

// export default {
//     state: {
//         user: null,
//         isLoggedIn: false,
//     },
//     mutations: {
//         set_user(state, data) {
//             state.user = data
//             state.isLoggedIn = true
//         },
//         reset_user(state) {
//             state.user = null
//             state.isLoggedIn = false
//         }
//     },
//     getters: {
//         isLoggedIn(state) {
//             return state.isLoggedIn
//         },
//         user (state) {
//             return state.user
//         }
//     },
//     actions: {
//         login({ dispatch, commit }, data) {
//             return new Promise((resolve, reject) => { 
//                 _axios.post('/login', data)
//                 .then((response) => {
//                     console.log(response)
//                     const token = response.data.token  
//                     localStorage.setItem('token', token) 
//                     setHeaderToken(token) 
//                     dispatch('get_user')
//                     resolve(response)
//                 })
//                 .catch((err) => {
//                     commit('reset_user')  
//                     localStorage.removeItem('token')
//                     reject(err)
//                 })
//             })
//             },
//             async get_user({commit}){ 
//             if(!localStorage.getItem('token')){
//                 return
//             }
//             try{ 
//                 let response = await _axios.get('/user')
//                 commit('set_user', response.data.data)
//             } catch (error){
//                 commit('reset_user') 
//                 removeHeaderToken()
//                 localStorage.removeItem('token')
//                 return error
//             } 
//         }
//     }
// }
export default {
    namespaced: true,
    state: {
        user    : {},
    },
    mutations: {
        set: (state, payload) => {
            state.user  = payload
        },
    },
    actions: {
        set: ({commit}, payload) => {
            commit('set', payload)
        },
    },
    getters: {
        user  : state => state.user,
        guest : state => Object.keys(state.user).length === 0,
    }
}